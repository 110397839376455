export const supportNetwork = { 
    1 : {
        name : "Ethereum",
        chainId : 1,
        rpc : "https://mainnet.infura.io/v3/2b819420936a42b08167b135cb2ba003",
        image : "binance-coin.png",
        symbol : 'ETH'
    }
}

export const RPC_URLS = {
    1 : "https://mainnet.infura.io/v3/2b819420936a42b08167b135cb2ba003"
  };
